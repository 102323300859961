import { Loader } from "@fanduel/og-fe-library";
import { HideAtMd } from "@/components/common/common";
import { PlayerNewsContainerProps } from "@/types/playerNewsHome";
import Divider from "@/components/divider";
import ErrorComponent from "@/components/empty/ErrorComponent";
import usePlayerNewsHome from "@/utils/hooks/usePlayerNewsHome";
import PlayerNewsCard from "./PlayerNewsCard";
import styles from "./playerNewsContainer.module.scss";

const PlayerNewsContainer = ({ sportName }: PlayerNewsContainerProps) => {
  const { data, isLoading, isError } = usePlayerNewsHome({
    sport: sportName,
    limit: 9,
  });
  const playerNewsData = data ?? [];
  const playerNews0 = playerNewsData.slice(0, 3);
  const playerNews1 = playerNewsData.slice(3, 6);
  const playerNews2 = playerNewsData.slice(6, 9);

  if (isLoading) {
    return (
      <div data-testid="player-news-container" className={styles.loading}>
        <Loader />
      </div>
    );
  }

  if (isError) {
    return (
      <div data-testid="player-news-container" className={styles.error}>
        <ErrorComponent />
      </div>
    );
  }

  return (
    <div data-testid="player-news-container" className={styles.container}>
      <div className={styles.cards}>
        {playerNews0.map((news, idx) => (
          <div key={news?.id}>
            <PlayerNewsCard news={news} sportName={sportName} />
            {idx < playerNews0.length - 1 && <Divider color="light" />}
            {idx === playerNews0.length - 1 && (
              <HideAtMd>
                <Divider color="light" />
              </HideAtMd>
            )}
          </div>
        ))}
      </div>
      <div className={styles.cards}>
        {playerNews1.map((news, idx) => (
          <div key={news?.id}>
            <PlayerNewsCard news={news} sportName={sportName} />
            {idx < playerNews1.length - 1 && <Divider color="light" />}
            {idx === playerNews1.length - 1 && (
              <HideAtMd>
                <Divider color="light" />
              </HideAtMd>
            )}
          </div>
        ))}
      </div>
      <div className={styles.cards}>
        {playerNews2.map((news, idx) => (
          <div key={news?.id}>
            <PlayerNewsCard news={news} sportName={sportName} />
            {idx < playerNews2.length - 1 && <Divider color="light" />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlayerNewsContainer;
