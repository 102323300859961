import Link from "@/components/link/Link";
import { Sport } from "@/graphql/gql/graphql";
import News from "@/components/svg/News";
import ArrowRight from "@/components/svg/ArrowRight";
import { useAmplitude } from "@/utils/hooks/useAmplitude";
import { NAV_LINK_CLICKED } from "@/constants/amplitude";
import styles from "./playerNewsHeader.module.scss";

type PlayerNewsHeaderProps = {
  sportName: Sport["name"];
  sportSlug: Sport["slug"];
};

const PlayerNewsHeader = ({ sportName, sportSlug }: PlayerNewsHeaderProps) => {
  const { logAmplitudeEvent } = useAmplitude();

  const headerText = `${sportName} Player News`;
  const linkText = `View all ${sportName} Player news`;

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>
        <News />
        <div>{headerText}</div>
      </h3>
      <Link
        onClick={() =>
          logAmplitudeEvent(NAV_LINK_CLICKED, {
            Module: "featured_news",
            "Link Text": linkText,
            "Link URL": `/${sportSlug}/player-news`,
          })
        }
        next
        href={`/${sportSlug}/player-news`}
      >
        <div className={styles.link}>
          {linkText}
          <ArrowRight />
        </div>
      </Link>
    </div>
  );
};

export default PlayerNewsHeader;
