import { PropsWithChildren } from "react";
import PlayerNewsMaybeLink from "@/components/playerNews/PlayerNewsMaybeLink";
import TeamPlayerAvatar from "@/components/teamPlayerAvatar";
import { ShortForm, ContentSportsEnum } from "@/graphql/gql/graphql";
import { getProfileImgUrls } from "@/utils/playerNewsHome";
import type { News } from "@/types/playerNewsHome";
import PlayerNewsPublish from "./PlayerNewsPublish";
import styles from "./playerNewsCard.module.scss";

type PlayerNewsCardProps = {
  news: News;
  sportName: ContentSportsEnum;
};

const Title = ({ children }: PropsWithChildren) => (
  <h2 className={styles.title}>{children}</h2>
);

const PlayerNewsCard = ({ news, sportName }: PlayerNewsCardProps) => {
  const { publishedDate, attribution, primaryRef } = news;
  const { name: sourceName, link: sourceLink } = attribution ?? {};
  const { primaryImgName, primaryImgUrl, secondaryImgName, secondaryImgUrl } =
    getProfileImgUrls(primaryRef, sportName);

  return (
    <div data-testid="player-news-card" className={styles.container}>
      <div className={styles.text}>
        <Title>
          <PlayerNewsMaybeLink news={news as unknown as ShortForm} />
        </Title>
        <PlayerNewsPublish
          sourceName={sourceName ?? ""}
          sourceLink={sourceLink ?? ""}
          date={publishedDate ?? ""}
          sportName={sportName}
        />
      </div>
      <div className={styles.avatar}>
        <TeamPlayerAvatar
          primaryImgName={primaryImgName}
          primaryImgUrl={primaryImgUrl}
          primaryImgSize={60}
          secondaryImgName={secondaryImgName}
          secondaryImgUrl={secondaryImgUrl}
          secondaryImgSize={30}
        />
      </div>
    </div>
  );
};

export default PlayerNewsCard;
