import NextLink from "next/link";
import { MouseEventHandler } from "react";
import styles from "./link.module.scss";

type LinkProps = {
  children: React.ReactNode;
  href: string;
  next: boolean;
  target?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

const Link = ({
  children,
  href,
  next,
  target = "_blank",
  onClick,
}: LinkProps) => {
  const internalLink = (
    <NextLink
      onClick={onClick}
      prefetch={false}
      href={href}
      className={styles.link}
    >
      {children}
    </NextLink>
  );

  const externalLink = (
    <a
      className={styles.link}
      href={href}
      onClick={onClick}
      target={target}
      rel="noreferrer"
    >
      {children}
    </a>
  );

  return next ? internalLink : externalLink;
};

export default Link;
