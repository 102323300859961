import { ContentSportsEnum } from "@/graphql/gql/graphql";
import Link from "@/components/link/Link";
import { relativeDate } from "@/utils/date";
import { PLAYER_NEWS_SOURCE_LINK_CLICKED } from "@/constants/amplitude";
import { useAmplitude } from "@/utils/hooks/useAmplitude";
import styles from "./playerNewsPublish.module.scss";

type PlayerNewsPublishProps = {
  sourceName: string;
  sourceLink?: string;
  date?: string;
  sportName: ContentSportsEnum;
};

const PlayerNewsPublish = ({
  date,
  sourceName,
  sourceLink,
  sportName,
}: PlayerNewsPublishProps) => {
  const { logAmplitudeEvent } = useAmplitude();
  return (
    <div className={styles.container}>
      {sourceLink ? (
        <Link
          onClick={() =>
            logAmplitudeEvent(PLAYER_NEWS_SOURCE_LINK_CLICKED, {
              "Link Text": sourceName ?? "",
              "Link URL": sourceLink ?? "",
              module: "featured_news",
              Competition: sportName,
            })
          }
          next={false}
          href={sourceLink}
        >
          {sourceName}
        </Link>
      ) : (
        <span className={styles.source}>{sourceName}</span>
      )}
      <span className={styles.bullet}>•</span>
      {date && (
        <time
          data-testid="relative-date"
          dateTime={date}
          suppressHydrationWarning
        >
          {relativeDate(date)}
        </time>
      )}
    </div>
  );
};

export default PlayerNewsPublish;
