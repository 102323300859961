import { Article, Maybe, Tool } from "@/graphql/gql/graphql";
import ArticleCard from "@/components/articleCard";
import Divider from "@/components/divider";
import SpotlightArticle from "@/components/spotlightArticle";
import ToolComponent from "@/components/home/hero/Tool";
import styles from "./template3Column.module.scss";

type Template3ColumnProps = {
  col1Article: Article;
  col2Articles: Article[];
  col3Articles: Article[];
  sportName: string;
  tool?: Maybe<Tool>;
};

const Template3Column = ({
  col1Article,
  col2Articles = [],
  col3Articles = [],
  sportName,
  tool,
}: Template3ColumnProps) => {
  const { title, description, slug, firstPublishedAt, hero, author } =
    col1Article;

  return (
    <div className={styles.container}>
      <SpotlightArticle
        author={author}
        fullwidthMobileImage={false}
        image={hero?.imageUrl?.url}
        link={`/${slug}`}
        origin="home_sport_articles"
        publishedOn={firstPublishedAt}
        snippet={description}
        sportName={sportName}
        title={title}
      />
      <div className={styles.col2Articles}>
        {col2Articles.map((article, idx) => (
          <div key={article.id}>
            <ArticleCard
              author={article.author}
              image={article.hero?.imageUrl?.url}
              link={`/${article.slug}`}
              origin="home_sport_articles"
              publishedOn={article.firstPublishedAt}
              sportName={sportName}
              title={article.title}
              displayHorizontal
            />
            {idx < col2Articles.length - 1 && <Divider color="light" />}
          </div>
        ))}
      </div>
      <div className={styles.col3Articles}>
        <div className={styles.divider}>
          <Divider color="light" />
        </div>
        {tool ? (
          <ToolComponent origin="home_sport_tool" condensed {...tool} />
        ) : undefined}
        {col3Articles.map((article, idx) => (
          <div key={article.id}>
            <ArticleCard
              author={article.author}
              image={article.hero?.imageUrl?.url}
              link={`/${article.slug}`}
              origin="home_sport_articles"
              publishedOn={article.firstPublishedAt}
              sportName={sportName}
              title={article.title}
              displayHorizontal
            />
            {idx < col3Articles.length - 1 && <Divider color="light" />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Template3Column;
