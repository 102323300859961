import cx from "classnames";
import styles from "./divider.module.scss";

type DividerProps = {
  color?: "light" | "dark";
};

const Divider = ({ color = "dark" }: DividerProps) => {
  const dividerClass = cx(styles.divider, {
    [styles.light]: color === "light",
    [styles.dark]: color === "dark",
  });

  return <hr data-testid="divider" className={dividerClass} />;
};

export default Divider;
